import React from "react";
import WeDoThatList from "../components/homepage/WeDoThatList";
import TrustUs from "../components/homepage/TrustUs";
import Services from "../components/homepage/Services";
import Layout from "../components/layout";

export default function howdowedothat() {
  return (
    <div>
      <Layout>
        <WeDoThatList />
        <TrustUs />
        <Services />
      </Layout>
    </div>
  );
}
